import AboutOne from "./sections/AboutOne";
import AboutSecond from "./sections/AboutSecond";
import AboutThird from "./sections/AboutThird";

const About = () => {
  return (
    <div>
      <AboutOne />
      <AboutSecond />
      <AboutThird />
    </div>
  );
};

export default About;
