import styled from "@emotion/styled";
import { CardActionArea } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { teal as color } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import ajay from "../../../assets/team/ajay.jpg";
import aastha from "../../../assets/team/astha.jpg";
import Chandni from "../../../assets/team/Chandni.jpg";
import Jaskaran from "../../../assets/team/Jaskaran.jpg";
import Jasmin from "../../../assets/team/Jasmin.jpg";
import sunit from "../../../assets/team/sunit.jpg";
import Section from "../../../components/Section";

const Container = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
`;

const TeamCard = ({
  image,
  name,
  designation,
}: {
  image: string;
  name: string;
  designation: string;
}) => {
  return (
    <Card>
      <CardActionArea>
        <CardMedia component="img" image={image} alt="green iguana" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {name}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {designation}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

const team = [
  {
    name: "Ar. Sunit Jindal",
    designation: "Partner Architect",
    image: sunit,
  },
  {
    name: "Ar. Aastha Gupta",
    designation: "Partner Architect",
    image: aastha,
  },
  {
    name: "Er. Ajay Jindal",
    designation: "Software Engineer",
    image: ajay,
  },
  {
    name: "Ar. Jasmin Bhagol",
    designation: "Junior architect",
    image: Jasmin,
  },
  {
    name: "Ar. Chandni Mittal",
    designation: "Interior Designer",
    image: Chandni,
  },
  {
    name: "Jaskaran Singh",
    designation: "3D visualizer",
    image: Jaskaran,
  },
];
const AboutThird = () => {
  return (
    <Section backgroundColor={color["A700"]} padding="5">
      <Container>
        <Typography variant="h4" component="h1" sx={{ color: "black" }}>
          Team
        </Typography>
      </Container>
      <Grid container spacing={2}>
        {team.map((member, index) => {
          return (
            <Grid xs={12} sm={6} md={4} key={index}>
              <TeamCard
                image={member.image}
                name={member.name}
                designation={member.designation}
              />
            </Grid>
          );
        })}
      </Grid>
    </Section>
  );
};

export default AboutThird;
