import styled from "@emotion/styled";
import { amber } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2

import Section from "../../../components/Section";

const Container = styled.div`
  text-align: center;
  margin-bottom: 1.5rem;
  color: black;
`;
const AboutSecond = () => {
  return (
    <Section backgroundColor={amber["500"]} padding="3" containerSize="lg">
      <Container>
        <Typography variant="h4" component="h1">
          A few words about us
        </Typography>
      </Container>
      <Grid container spacing={2}>
        <Grid xs={12} sm={6}>
          <Typography variant="body1" sx={{ color: "black" }}>
            At Grey Form Studio, we all come to work every day because we want
            to solve the biggest problem in design, construction and interiors
            of the buildings. Everyone is guessing. Contractors don’t know what
            design to implement, how to make it habitable, or even what to price
            them at. Users don’t know where their target Architects are, how to
            reach them, or even how much they need to spend in order to do so.
            Material companies aren’t sure which categories are growing the
            quickest, and are users really spending their time (and money)
            worth.
          </Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography variant="body1" sx={{ color: "black" }}>
            Nowadays, people use data on web world to make more informed
            decisions. But! Are they really worth applying to their requirements
            and conditions. At Grey Form Studio we believe every individual has
            its own way of living and requirements thus, a designed space should
            be a part of their life and not just a product delivered to them.
            Our mission at Grey Form Studio is to make the Architecture easy and
            more transparent to people. After all architecture is all about
            commonsense. Today we provide the young actionable team in the
            industry to help people make their architectural decisions more
            wisely. We want to be available to as many people as possible (not
            just the top 5%).
          </Typography>
        </Grid>
      </Grid>
    </Section>
  );
};

export default AboutSecond;
